import React,{useState} from "react"
import NoImage from "../../images/no-image.png"
import SocialShare from "../SocialShare/SocialShare"
import "./CareerContactModule.scss"
import { Modal } from "react-bootstrap"
import DefaultForm from "../forms/default-form-module"
import FormFields from "../../../static/forms/career.json"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const CareerContactModule = props => {
  const negotiatorData = props?.propertyInfo?.team

  const [show, setShow] = useState(false)

  const handleModal = (data) => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="career-contact-component">
      <div className="career-contact-content">
        <div className="career-team-desktop">
          <div className="cta-holder">
            <a className="whatsapp-link">
              <button onClick={handleModal} className="button button-filled-green">
                <span>apply for job</span>
              </button>
            </a>

            <div className="cta-secondary">
              <a>
                <SocialShare iconClass="icon icon-share" shareText="Share" />
              </a>
            </div>
          </div>

          <div className="career-team-data">
            <div className="image-section">
              {negotiatorData?.image ? (
                <img src={negotiatorData?.image?.url} alt="negotiator" />
              ) : (
                <img src={NoImage} alt="negotiator" />
              )}
            </div>
            <div className="career-team-info">
              <h5 className="name">{negotiatorData?.name} </h5>
              <p className="designation">{negotiatorData?.designation}</p>
              {negotiatorData?.language_speaks&&
              <p className="language-speak">Speaks {negotiatorData?.language_speaks}</p>
              }
            </div>
          </div>
        </div>
        <div className="career-val-desktop">
          <div className="val-card-wrapper">
            <h5>{props?.propertyInfo?.title}</h5>
            <ContentModule
              Content={props?.propertyInfo?.content?.data?.content}
            />
            <button onClick={handleModal} className="button button-outline-black">
              send us your cv
            </button>
            {/* </Link> */}
            {/* <CTALink
              class="button button-outline-black"
              link={props?.propertyInfo?.cta?.link}
              title={props?.propertyInfo?.cta?.title}
            ></CTALink> */}
          </div>
        </div>
      </div>
      {
         <Modal
         show={show}
         // onHide={handleClose}
         backdrop="static"
         centered
         dialogClassName="modal-popup-form"
       >
         <Modal.Body>
           <div className="popup-form-wrapper">
             <div className="close-modal" onClick={() => handleClose()}>
               <i className="icon black-cancel-icon"></i>
             </div>
             <div>
               <DefaultForm
               fields={FormFields} 
               sourceUrl={pageurl}
               formTitle="Careers Submission"/>
             </div>
           </div>
         </Modal.Body>
       </Modal>
      }
    </div>
  )
}

export default CareerContactModule
