import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import FormFields from "../../static/forms/career.json"
import { PageLinks } from "../common/site/page-static-links"
import CareerContactModule from "../components/CareerContactModule/CareerContactModule"
import SocialShare from "../components/SocialShare/SocialShare"
import DefaultForm from "../components/forms/default-form-module"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const CareerDetailIntro = loadable(() =>
  import("../components/CareerDetailIntro/CareerDetailIntro")
)
const CareerDetailDesc = loadable(() =>
  import("../components/CareerDetailDesc/CareerDetailDesc")
)
const CareerDetailSidebar = loadable(() =>
  import("../components/CareerDetailSidebar/CareerDetailSidebar")
)
// const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const CareerDetail = ({ data }, props) => {
  const PageData = data?.strapiCareer

  // Sticky scroll
  const [scroll, setScroll] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 80)
    })
  }, [])
  // Sticky scroll

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const { isLargeScreen } = useDeviceMedia()
  // console.log(PageData,"67890")
  const teamData = PageData?.sidebar?.team

  const pageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (pageData?.add_page_modules?.length > 0) {
    const results = pageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  return (
    <Layout
      popUpData={popUpData}
      nonpropertyDetails
      dark={true}
      footertag={"career-contact"}
    >
      <div className="layout-padding-top"></div>
      <BreadcrumbModule
        mainparentname={PageLinks.about_label}
        mainparent={PageLinks.about}
        parentname={PageLinks.career_label}
        parent={PageLinks.career}
        pagename={PageData.title}
      />

      <div className="career-detail-wrapper">
        <Container>
          <Row>
            <Col xl={7}>
              <CareerDetailIntro {...PageData} />

              <CareerDetailDesc {...PageData} />
            </Col>

            <Col xl={1}></Col>

            {!isLargeScreen && (
              <Col xl={4}>
                <div
                  className={`career-sidebar position-sticky ${
                    scroll ? "scrolled" : ""
                  }`}
                >
                  <CareerContactModule propertyInfo={PageData.sidebar} />
                </div>
              </Col>
            )}
          </Row>
        </Container>

        {isLargeScreen && teamData && (
          <div className="career-footer-ctas">
            <a className="button button-filled-green" onClick={handleModal}>
              <span>Apply</span>
            </a>
            <a className="button button-outline-grey">
              <SocialShare iconClass="icon icon-share" shareText="Share" />
            </a>
          </div>
        )}
      </div>

      {
        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form"
        >
          <Modal.Body>
            <div className="popup-form-wrapper">
              <div className="close-modal" onClick={() => handleClose()}>
                <i className="icon black-cancel-icon"></i>
              </div>
              <div>
                <DefaultForm
                  fields={FormFields}
                  sourceUrl={pageurl}
                  formTitle="Careers Submission"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

      {/* <div className="d-xl-none">
                <FooterContactMobile
                    tag="career-detail"
                />
            </div> */}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiCareer
  const metaDescription = `Join our dynamic team as a ${PageData.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`

  return <Seo title={PageData.title} description={metaDescription} />
}
export default CareerDetail

export const query = graphql`
  query ($page_id: Int) {
    strapiCareer(strapi_id: { eq: $page_id }) {
      ...CareerFragment
      sidebar {
        team {
          image {
            url
          }
          designation
          name
          negotiator_id
          email
          phone
          whatsapp
          language_speaks
        }
        title
        content {
          data {
            content
          }
        }
        cta {
          title
          custom_link
          link_type
          link {
            slug
          }
        }
      }
      job_details {
        data {
          job_details
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "careers_landing_page" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
